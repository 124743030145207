'use strict';

// ==================================================================
//  Config
// ==================================================================
var config = {
    subnavSlide: 0.3,
    imgFadeSpeed: 1.25
};

// ==================================================================
//  Variables / DOM selectors
// ==================================================================
var $root = $('html');
var $header = $root.find('header');
var $wrapper = $root.find('.wrapper');
var $lecturesWrapper = $root.find('.lectures-wrapper');
var $lecturesWrapperMobile = $root.find('.lectures-wrapper-mobile');
var $loader = $root.find('.loader');
var $nav = $root.find('.nav-wrapper');
var $navToggles = $header.find('.nav-toggle');
var $navToggle = $header.find('.header-container').children('.nav-toggle');
var $lazy = $root.find('.lazy');
var $volumeSwitch = $('.volume-switch');
var $projectContainer = $root.find('.project-container');
var $filterNav = $header.find('.filter-wrapper');
var $filterToggles = $header.find('.filter-toggle');
var $filterToggle = $header.children('.filter-toggle');
var $submenuToggle = $root.find('.menu-item-has-children:not(.menu-item-object-custom)').find('span');
var $methodsToggle = $root.find('.methods-wrapper').children('p');
var $methodsSubToggle = $root.find('.content-element-methods, .content-element-services').find('ul').find('h5');
var $lecturesOptions = $root.find('.lectures-option');
var $lecturesItems = $root.find('.lectures-items');
var $lecturesContainers = $root.find('.lectures-container');
var $mobileLecturesContainer = $root.find('.lectures-container-mobile');
var $mobileLecturesToggle = $mobileLecturesContainer.children('p');
var $fitVids = $root.find('.fit-vids');

// ==================================================================
//  Document ready event
// ==================================================================
$(document).ready(function () {

    // Toggle main navigation when clicking buttons or when clicking anywhere but the header
    $navToggles.on('click', function () {
        toggleNav($nav, $navToggle);
    });
    $header.on('click', function (e) {
        e.stopPropagation();
    });
    $nav.find('.navigation').on('click', function (e) {
        e.stopPropagation();
    });
    $root.on('click', function () {
        $nav.hasClass('open') ? toggleNav($nav, $navToggle) : '';
        $filterNav.hasClass('open') ? toggleNav($filterNav, $filterToggle) : '';
    });

    // Toggle filter categories
    $filterToggles.on('click', function () {
        toggleNav($filterNav, $filterToggle);
    });
    $root.on('click', function () {
        $filterToggle.hasClass('open') ? toggleNav($filterNav, $filterToggle) : '';
    });

    // Toggle subnav height
    animateSubnavHeight($submenuToggle, $submenuToggle.parent(), 'ul');
    $submenuToggle.on('click', function (e) {
        e.stopPropagation();
        animateSubnavHeight($(this), $(this).parent(), 'ul');
    });

    // Toggle sound of homepage videos
    $volumeSwitch.on('click', function () {
        if (!$(this).hasClass('on')) {
            $('.home').find('video').prop('muted', '');
            $volumeSwitch.addClass('on');
        } else {
            $('.home').find('video').prop('muted', 'muted');
            $volumeSwitch.removeClass('on');
        }
    });

    // Start/Rewind project overview videos
    $projectContainer.on('mouseenter', function () {
        if ($(this).find('video').length > 0) {
            $(this).find('video').get(0).play();
        }
    }).on('mouseleave', function () {
        if ($(this).find('video').length > 0) {
            $(this).find('video').get(0).pause();
        }
    });

    // Toggle methods container height
    animateSubnavHeight($methodsToggle.find('span'), $methodsToggle.parent(), 'ul');
    $methodsToggle.on('click', function () {
        animateSubnavHeight($(this).find('span'), $(this).parent(), 'ul');
    });

    // Toggle single methods height
    animateSubnavHeight($methodsSubToggle.find('span'), $methodsSubToggle.parent(), '.methods-text');
    $methodsSubToggle.on('click', function () {
        animateSubnavHeight($(this).find('span'), $(this).parent(), '.methods-text');
    });

    // Toggle lectures elements
    $lecturesOptions.on('click', function () {
        animateLecturesHeight($(this));
    });

    // Toggle mobile lectures height
    animateSubnavHeight($mobileLecturesToggle, $mobileLecturesContainer, 'div');
    $mobileLecturesToggle.on('click', function () {
        animateSubnavHeight($(this).find('span:not(.wpcf7-form-control-wrap)'), $(this).parent(), 'div');
    });

    // Open lectures element if hash is set
    if (location.hash && $lecturesOptions.length > 0 && $(window).width() > 768) {
        if (location.hash.indexOf('wpforms') > 0) {
            animateLecturesHeight($('.lectures-wrapper').find('#booking-request'));
        } else {
            animateLecturesHeight($('.lectures-wrapper').find(location.hash));
        }
    } else if (location.hash && $lecturesOptions.length > 0 && $(window).width() < 769) {
        setTimeout(function () {
            TweenMax.to(window, config.subnavSlide, { scrollTo: $wrapper.offset().top + $wrapper.height() });
            if (location.hash.indexOf('wpforms') > 0) {
                animateSubnavHeight($('.lectures-wrapper-mobile').find('#booking-request').find('span'), $('.lectures-wrapper-mobile').find('#booking-request'), 'div');
            } else {
                animateSubnavHeight($('.lectures-wrapper-mobile').find(location.hash).find('span'), $('.lectures-wrapper-mobile').find(location.hash), 'div');
            }
        }, config.subnavSlide);
    }

    // Initialize Unveil plugin
    $lazy.unveil(100, function () {
        $(this).on('load', function () {
            this.style.opacity = 1;
            TweenMax.from(this, config.imgFadeSpeed, { y: 100, ease: Power4.easeOut });
        });
    });

    // Call fitVids plugin
    $fitVids.length > 0 ? $fitVids.fitVids() : '';
});

// ==================================================================
//  Window pageshow event
// ==================================================================
$(window).on('pageshow', function () {

    // Fade in wrapper when the site is loaded
    $wrapper.addClass('loaded');
    TweenMax.from($wrapper, config.imgFadeSpeed, { y: 100, ease: Power4.easeOut });

    $lecturesWrapper.addClass('loaded');
    TweenMax.from($lecturesWrapper, config.imgFadeSpeed, { y: 100, ease: Power4.easeOut });

    $lecturesWrapperMobile.addClass('loaded');
    TweenMax.from($lecturesWrapperMobile, config.imgFadeSpeed, { y: 100, ease: Power4.easeOut });

    TweenMax.to($loader, config.imgFadeSpeed, { autoAlpha: 0, ease: Power4.easeOut });

    // Play transition animation when clicking an internal link
    $('a').click(function (e) {
        var link = $(this).attr('href');
        if (location.hostname === this.hostname && !$(this).attr('target') || !this.hostname.length && link.indexOf('mailto:') < 0) {

            // Don’t disable link when modifier key is pressed
            if (!e.shiftKey && !e.metaKey && !e.ctrlKey) {
                e.preventDefault();
                $wrapper.removeClass('loaded');
                $lecturesWrapper.removeClass('loaded');
                $lecturesWrapperMobile.removeClass('loaded');
                TweenMax.to($loader, config.imgFadeSpeed, { autoAlpha: 1, ease: Power4.easeOut });
                setTimeout(function () {
                    window.location = link;
                }, 500);
                $nav.hasClass('open') ? toggleNav($nav, $navToggle) : '';
                $filterNav.hasClass('open') ? toggleNav($filterNav, $filterToggle) : '';
            }
        }
    });
});

// ==================================================================
//  Document scroll event
// ==================================================================
$(document).on('scroll', function () {

    // Set correct top value for nav
    setNavPosition();
});

// ==================================================================
//  Window resize event
// ==================================================================
$(window).on('resize', function () {

    // Set correct top value for nav
    setNavPosition();
});

// ==================================================================
//  Functions
// ==================================================================

// Toggle main navigation
function toggleNav($elem, $elemToggle) {
    if ($elem.hasClass('open')) {
        $elem.removeClass('open');
        $elemToggle.removeClass('open');

        // Set correct top value for nav
        $elem.on('transitionend', setNavPosition);
        $('.home').css('overflow', '');
    } else {
        $elem.addClass('open');
        $elemToggle.addClass('open');
        $('.home').css('overflow', 'auto');
    }
}

// Toggle subnav height
function animateSubnavHeight($elem, $parent, $child) {
    var $linkElem = $parent;
    var $content = $parent.children($child);

    if ($linkElem.hasClass('open')) {
        TweenMax.to($content, config.subnavSlide, { height: 0 });
        TweenMax.to($elem, config.subnavSlide, { rotation: 0, transformOrigin: 'center center' });
        $linkElem.removeClass('open');
    } else {
        TweenMax.set($content, { height: 'auto' });
        TweenMax.from($content, config.subnavSlide, { height: 0 });
        TweenMax.to($elem, config.subnavSlide, { rotation: 45, transformOrigin: 'center center' });
        $linkElem.addClass('open');
    }
}

// Toggle lectures height
function animateLecturesHeight($elem) {
    var $elemContainer = $('.' + $elem.data('selector'));

    if ($lecturesOptions.hasClass('open') && !$elem.hasClass('open')) {
        TweenMax.to($lecturesContainers, config.subnavSlide, { height: 0, onComplete: function onComplete() {
                $lecturesOptions.removeClass('open');
                $lecturesItems.removeClass('visible');
                TweenMax.set($elemContainer, { height: 'auto' });
                TweenMax.from($elemContainer, config.subnavSlide, { height: 0, onComplete: function onComplete() {
                        $elemContainer.find('.lectures-items').addClass('visible');
                        TweenMax.to(window, config.subnavSlide, { scrollTo: $wrapper.offset().top + $wrapper.height() + 190 });
                    } });
                $elem.addClass('open');
            } });
    } else if ($elem.hasClass('open')) {
        TweenMax.to($lecturesContainers, config.subnavSlide, { height: 0 });
        $lecturesOptions.removeClass('open');
        $lecturesItems.removeClass('visible');
    } else {
        TweenMax.set($elemContainer, { height: 'auto' });
        TweenMax.from($elemContainer, config.subnavSlide, { height: 0, onComplete: function onComplete() {
                $elemContainer.find('.lectures-items').addClass('visible');
                TweenMax.to(window, config.subnavSlide, { scrollTo: $wrapper.offset().top + $wrapper.height() + 190 });
            } });
        $elem.addClass('open');
    }
}

// Set correct top value for nav
function setNavPosition() {
    if (!$nav.hasClass('open') && $nav.height() < $root.height() + 50 && $(window).width() < 769) {
        $nav.css('top', $(window).scrollTop() + 50);
    } else if ($(window).width() > 768) {
        $nav.css('top', '');
    }
}